import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const ServicesPage = ({
  data: {
    site
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>Our Services — {site.siteMetadata.title}</title>
        <meta name="description" content={"Our Services " + site.siteMetadata.description} />
      </Helmet>
      <div className="-services">
        <h1 className="post-title">Our Services</h1>
        <p>Working with the leading hand surgeons in Sydney, we provide essential pre and post operative therapy to help each patient recuperate and regain flexibility, strength and movement.</p>
        <p>For those patients seeking non-surgical treatment, we provide a range of modern therapies, exercises and treatments to help reduce pain, regain movement, and build flexibility and strength to restore hand function.</p>
        <p>Treatment services available include:</p>
        <ul>
          <li>full assessments of hand function</li>
          <li>sensory testing</li>
          <li>muscle testing</li>
          <li>grip strength evaluation</li>
          <li>sensory re-education</li>
          <li>desensitisation</li>
          <li>therapeutic massage</li>
          <li>tailored exercise programs</li>
          <li>joint and tendon mobilisation</li>
          <li>scar management</li>
          <li>oedema control</li>
          <li>splinting – resting splints, protective splints, exercise and mobilisation splints and working splints.</li>
        </ul>
      </div>
    </Layout>
  )
}
export default ServicesPage
export const pageQuery = graphql`
  query ServicesPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`